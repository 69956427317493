@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans Bold';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans/OpenSans-Medium.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'National';
  src: url(./assets/fonts/National/national-regular.woff2) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'National';
  src: url(./assets/fonts/National/national-medium.woff2) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'National Finacier';
  src: url(./assets/fonts/National/financier-display-bold.woff2) format('woff');
  font-weight: 500;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Add custom styling to scrollbar */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 1px;
  height: 8px;
}

.space-bar::-webkit-scrollbar,
.main-container::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #94a6e7;
}

@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin;
  }
  .space-bar,
  .main-container {
    scrollbar-width: none;
  }
}

/* Set core root defaults */

#root {
  height: 100%;
}

:root {
  --app-height: 100%;
  --swiper-theme-color: #3b2ec9 !important;
}
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100%;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  user-select: none;
  font-family: 'National', 'Poppins', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  height: var(--app-height);
  overflow: hidden;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.progress-bar {
  background: linear-gradient(#9a69fb, #3f26f6);
}

.mission-progress {
  color: #ffbb43 !important;
}

.MuiCircularProgress-circle {
  stroke: #c1c5d0;
}

.MuiFormHelperText-root {
  top: 100%;
  bottom: auto;
}

/* Swiper CSS */

.swiper-container {
  overflow: visible;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute !important;
  top: 25% !important;
  background-size: cover;
}

.swiper-button-next {
  background-image: url('./assets/icons/back_icon.png');
  right: 50px !important;
}

.swiper-button-prev {
  background-image: url('./assets/icons/forward_icon.png');
  left: 50px !important;
}

.swiper-horizontal > .swiper-scrollbar {
  height: 30px !important;
  background-color: rgba(162, 167, 182, 0.44);
  padding: 0 3px !important;
  margin-bottom: 10px;
}

.swiper-scrollbar-drag {
  background: linear-gradient(#435f63, #1e222a) !important;
  border-radius: 8px !important;
  height: 24px !important;
  margin: 3px 0;
}

.flat-bullets {
  height: 4px !important;
  width: 27px !important;
  border-radius: 2px !important;
  background-color: #3b2ec9;
}

.share-icon circle:hover {
  fill: #3b2ec9 !important;
}

.chat-search input {
  padding: 7.5px 12px 7.5px 12px
}
